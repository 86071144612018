<template>
  <v-dialog
    v-model="dialog"
    @click:outside="close"
    persistent
    max-width="800px"
  >
    <v-card class="dialog-top-color">
      <v-app-bar flat color="rgba(0, 0, 0, 0)">
        <v-toolbar-title>
          {{ $t("account.acceptRequestTitle") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon color="error"> mdi-close-box </v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>
        <v-row class="mb-3" no-gutters>
          <v-col>
            <p
              v-html="
                $t('account.acceptRequestDescription', {
                  accountantName: accountant.fullName,
                })
              "
            ></p>
          </v-col>
        </v-row>
        <v-form ref="acceptRequestForm" v-model="validForm" lazy-validation>
          <v-row no-gutters>
            <v-col>
              <v-text-field
                v-model="firstName"
                :label="$t('label.firstName')"
                :hint="$t('account.firstNameFieldHint')"
                persistent-hint
                solo
                :rules="nameRules"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field
                required
                v-model="lastName"
                :label="$t('label.lastName')"
                :hint="$t('account.lastNameFieldHint')"
                persistent-hint
                solo
                :rules="nameRules"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-checkbox
                color="accent"
                v-model="termsPrivacy"
                :rules="[
                  (v) => !!v || $t('account.acceptRequestTermsPrivacyRequired'),
                ]"
                :label="$t('account.acceptRequestTermsPrivacy')"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="acceptRequest"
          tile
          elevation="0"
          color="accent"
          class="mb-3"
          :loading="loading"
        >
          {{ $t("account.acceptRequestButton") }}</v-btn
        >
        <v-spacer v-if="$vuetify.breakpoint.xs" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
export default {
  name: "accept-accountant-request-dialog",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    requestId: {
      type: String,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      firstName: null,
      lastName: null,
      termsPrivacy: false,
      loading: false,
      validForm: false,
      addAddressRules: [
        (v) => !!v || this.$t("addAddress.addressRequiredText"),
      ],
      nameRules: [(v) => !!v || this.$t("addAddress.nameRequiredText")],
    };
  },
  methods: {
    ...mapActions("accountant", ["acceptAccountant"]),
    ...mapActions("alert", ["createAlert"]),
    close() {
      this.$emit("update:dialog", false);
    },
    acceptRequest() {
      if (!this.$refs.acceptRequestForm.validate()) {
        return;
      }
      this.loading = true;
      const payload = {
        requestId: this.requestId,
        firstName: this.firstName,
        lastName: this.lastName,
        termsPrivacy: this.termsPrivacy,
      };
      this.acceptAccountant(payload)
        .then(() => {
          this.createAlert({
            message: this.$t("message.accountantConfirmed"),
            type: "success",
          });
          this.$emit("requestAccepted", true);
        })
        .catch(() => {
          this.createAlert({
            message: this.$t("message.genericError"),
            type: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    ...mapFields("loggedUser", ["loggedUser"]),
    ...mapFields("accountant", ["accountant"]),
  },
  mounted() {
    this.firstName = this.loggedUser.firstName;
    this.lastName = this.loggedUser.lastName;
  },
};
</script>

<style></style>