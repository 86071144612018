<template>
    <v-container fluid>
        <v-card class="pa-3" elevation="0">
            <v-tabs-items v-model="tab">
                <v-tab-item value="account-tab" class="pa-3">
                    <v-row>
                        <v-col>
                            <h3>{{ $t('settings.accountTabTitle') }}</h3>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mb-3">
                        <v-col>
                            {{ $t('settings.accountTabDescription') }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <v-text-field :label="$t('label.email')" v-model="newUser.email" readonly
                                :hint="$t('label.email')" persistent-hint solo></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <v-text-field :label="$t('label.nickname')" v-model="newUser.nickname"
                                :hint="$t('label.nickname')" persistent-hint solo></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0 py-0">
                        <v-col>
                            <v-text-field :label="$t('label.firstName')" v-model="newUser.firstName"
                                :hint="$t('label.firstName')" persistent-hint solo></v-text-field>
                        </v-col>

                        <v-col>
                            <v-text-field :label="$t('label.lastName')" v-model="newUser.lastName"
                                :hint="$t('label.lastName')" persistent-hint solo></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0 py-0">
                        <v-col>
                            <delete-account-button @accountDeleted="logoutUser"></delete-account-button>
                        </v-col>
                    </v-row>
                </v-tab-item>
                <v-tab-item value="accountant-tab" class="pa-3">
                    <v-row>
                        <v-col>
                            <h3>{{ $t('settings.accountantTabTitle') }}</h3>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mb-3">
                        <v-col>
                            {{ $t('settings.accountantTabDescription') }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <v-text-field :label="$t('label.userId')" v-model="newUser.id" readonly
                                :hint="$t('label.userId')" persistent-hint solo>
                            <template v-slot:prepend-inner>
                                <copy-address-button :address="newUser.id"></copy-address-button>
                            </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <accountant-card :accountant="accountant" :link="link"></accountant-card>
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                </v-tab-item>
                <v-tab-item value="subscription-tab" class="pa-3">
                    <v-row>
                        <v-col>
                            <h3>{{ $t('settings.subscriptionTabTitle') }}</h3>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mb-3">
                        <v-col>
                            {{ $t('settings.subscriptionTabDescription') }}
                        </v-col>
                    </v-row>
                    <template v-if="newUser.pricingPlan.taxYearsAvailable.length > 0">
                        <v-row v-for="(taxYearAvailable, i) in newUser.pricingPlan.taxYearsAvailable" :key="i" no-gutters>
                            <v-col cols="auto">
                                <v-text-field
                                    :value="$options.filters.PricingPlanFormatter(taxYearAvailable.pricingPlanType) + ' ' + taxYearAvailable.taxYear"
                                    :label="$t('label.previousYears')" readonly solo persistent-hint
                                    :hint="$t('plan.acquired') + ': ' + $options.filters.OnlyDateFormatter(taxYearAvailable.purchaseDate)">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </template>
                </v-tab-item>
                <v-tab-item value="settings-tab" class="pa-3">
                    <v-row>
                        <v-col>
                            <h3>{{ $t('settings.settingsTabTitle') }}</h3>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mb-3">
                        <v-col>
                            {{ $t('settings.settingsTabDescription') }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mb-3">
                        <v-col>
                            <b>{{ $t('settings.settingsTabApplication') }}</b>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <currency-select :selectedCurrency="newUser.settings.currency" :currencies="defaultCurrencies"
                                @updateCurrency="updateCurrency">
                            </currency-select>
                        </v-col>
                    </v-row>
                    <!--
                    <v-row no-gutters>
                        <v-col>
                            <nft-evaluation-method-select
                                :selectedNftEvaluationMethod="newUser.settings.nftEvaluationMethod"
                                @updateNftEvaluationMethod="updateNftEvaluationMethod"></nft-evaluation-method-select>
                        </v-col>
                    </v-row>
                    -->
                    <v-row no-gutters>
                        <v-col>
                            <v-switch v-model="newUser.settings.hideZeroValue" inset color="accent"
                                :label="$t('label.hideZeroValue')"></v-switch>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <v-switch v-model="newUser.settings.hideDust" inset :label="$t('label.hideDust')"
                                color="accent"></v-switch>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mb-3">
                        <v-col>
                            <b>{{ $t('settings.settingsTabTax') }}</b>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <country-select :selectedCountry="newUser.settings.country" :countries="defaultCountries"
                                @updateCountry="updateCountry">
                            </country-select>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <currency-select :selectedCurrency="newUser.settings.taxCurrency"
                                :currencies="defaultTaxCurrencies" @updateCurrency="updateTaxCurrency"
                                :disabled="isChoiceDisabled">
                            </currency-select>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <v-select v-model="newUser.settings.costBasisMethod" :items="defaultCostBasisMethods"
                                :label="$t('tax.costBasisMethod')" :disabled="isChoiceDisabled" solo
                                :hint="$t('label.costBasisMethod')" persistent-hint>
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="auto">
                            <v-switch v-model="newUser.settings.emoney" inset :label="$t('tax.emoney')"
                                color="accent">
                                <template slot="append">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on">
                                                mdi-help-circle-outline
                                            </v-icon>
                                        </template>
                                        <span>
                                            {{ $t('tax.emoneySwitchTooltip') }}
                                        </span>
                                    </v-tooltip>
                                </template>
                            </v-switch>
                        </v-col>
                    </v-row>
                    
                    <v-row no-gutters class="mb-3">
                        <v-col>
                            <b>{{ $t('settings.settingsTabTaxEmoney') }}</b>
                        </v-col>
                    </v-row>
                    <v-row no-gutters v-for="(emoney,i) in $EMONEY_TOKEN" :key="i">
                        {{ emoney.name }} ({{ emoney.symbol }})
                    </v-row>
                </v-tab-item>
                <v-tab-item value="appearance-tab" class="pa-3">
                    <v-row>
                        <v-col>
                            <h3>{{ $t('settings.appearanceTabTitle') }}</h3>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mb-3">
                        <v-col>
                            {{ $t('settings.appearanceTabDescription') }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <language-select :selectedLanguage="newUser.settings.language" @updateLanguage="updateLanguage">
                            </language-select>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <theme-switch :selectedTheme="newUser.settings.theme" @updateTheme="updateTheme"></theme-switch>
                        </v-col>
                    </v-row>
                </v-tab-item>
                <v-tab-item value="notification-tab" class="pa-3">
                    <v-row>
                        <v-col>
                            <h3>{{ $t('settings.notificationTabTitle') }}</h3>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mb-3">
                        <v-col>
                            {{ $t('settings.notificationTabDescription') }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <v-switch v-model="newUser.settings.systemMailNotification" color="accent" inset
                                :label="$t('label.systemMailNotification')">
                            </v-switch>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <v-switch v-model="newUser.settings.communicationMailNotification" inset
                                :label="$t('label.communicationMailNotification')" color="accent"></v-switch>
                        </v-col>
                    </v-row>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
//import NftEvaluationMethodSelect from '../select/NftEvaluationMethodSelect.vue';
import LanguageSelect from '../select/LanguageSelect.vue';
import CountrySelect from '../select/CountrySelect.vue';
import CurrencySelect from '../select/CurrencySelect.vue';
import ThemeSwitch from '../switch/ThemeSwitch.vue';
import DeleteAccountButton from '../button/DeleteAccountButton.vue';
import CopyAddressButton from '../button/CopyAddressButton.vue';
import AccountantCard from '../card/AccountantCard.vue';
export default {
    name: 'settings-tabs',
    components: {
        LanguageSelect, CurrencySelect, ThemeSwitch, 
        //NftEvaluationMethodSelect,
        DeleteAccountButton, CountrySelect, CopyAddressButton,
        AccountantCard
    },
    props: {
        tab: {
            type: String,
            required: true
        },
        newUser: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            deleteAccountDialog: false,
            defaultCurrencies: ["USD", "EUR", "BTC", "ETH"],
            defaultTaxCurrencies: ["USD", "EUR"],
            defaultCountries: [{ value: "ITALY", text: "ITALY", disabled: false }, { value: "REST_OF_THE_WORLD", text: "REST_OF_THE_WORLD", disabled: false }],
            defaultCostBasisMethods: [
                {
                    text: "LIFO",
                    value: "LIFO",
                },
                {
                    text: "FIFO",
                    value: "FIFO",
                },
                {
                    text: this.$t("tax.costBasisMethodAverageCost"),
                    value: "AVERAGE_COST",
                }
            ],
        }
    },
    computed: {
        ...mapGetters("loggedUser", ["isFreeUser"]),
        ...mapFields("accountant", ["accountant", "link"]),
        isChoiceDisabled() {
            if (this.newUser.settings.country === "ITALY") {
                return true
            }
            return false
        },
    },
    methods: {
        ...mapActions("payment", ["createPortalSession"]),
        ...mapActions("accountant", ["getAccountant", "getUserLink"]),
        getPastDue(date) {
            const endDate = new Date(date);
            const result = new Date(date);
            result.setDate(endDate.getDate() + this.$LEEWAY_DAYS);
            return result.toISOString();
        },
        updateLanguage(language) {
            this.newUser.settings.language = language
        },
        updateCountry(country) {
            this.newUser.settings.country = country
            if (country === "ITALY") {
                this.newUser.settings.taxCurrency = "EUR"
                this.newUser.settings.costBasisMethod = "LIFO"
            }
        },
        updateTheme(theme) {
            this.newUser.settings.theme = theme
        },
        updateCurrency(currency) {
            this.newUser.settings.currency = currency
        },
        updateTaxCurrency(currency) {
            this.newUser.settings.taxCurrency = currency
        },
        updateEmoney(emoney) {
            this.newUser.settings.emoney = emoney
        },
        updateThreshold(threshold) {
            this.newUser.settings.threshold = threshold
        },
        updateNftEvaluationMethod(nftEvaluationMethod) {
            this.newUser.settings.nftEvaluationMethod = nftEvaluationMethod
        },
        updateHideDust(hideDust) {
            this.newUser.settings.hideDust = hideDust
        },
        updateHideZeroValue(hideZeroValue) {
            this.newUser.settings.hideZeroValue = hideZeroValue
        },
        updateSystemMailNotification(systemMailNotification) {
            this.newUser.settings.systemMailNotification = systemMailNotification
        },
        updateCommunicationMailNotification(communicationMailNotification) {
            this.newUser.settings.communicationMailNotification = communicationMailNotification
        },
        goToManagePlans() {
            // You will be redirected to Stripe's secure portal page
            this.loading = true;
            this.createPortalSession().then(response => {
                window.location.href = response.data;
            }).finally(() => {
                this.loading = false;
            });
        },
        logoutUser(success) {
            if (success) {
                this.$keycloak.logoutFn();
            }
        },
    },
    created(){
        this.getUserLink().then(() => {
            if (this.link.accountantId) {
                const accountantId = this.link.accountantId;
                this.getAccountant(accountantId);
            }  
        });
    }
}
</script>

<style></style>