<template>
  <v-container fluid>
    <v-card v-if="link.id" class="accountant-card" outlined>
      <v-card-title>
        <v-row no-gutters :align="'start'" :justify="'start'">
          <v-col cols="auto" :align="'start'" :justify="'start'">
            <v-row no-gutters>
              <v-col>
                <h2>{{ accountant.fullName }}</h2>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <small class="small-text">{{
                  link.status | LinkStatusFormatter
                }}</small>
              </v-col>
            </v-row>
          </v-col>
          <v-spacer />
          <v-col cols="auto">
            <v-avatar size="60">
              <v-icon size="60" color="grey">mdi-account-circle</v-icon>
            </v-avatar>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col>
            <v-icon class="mr-1" small>mdi-email-outline</v-icon>
            {{ accountant.email }}
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-1">
          <v-col>
            <v-icon class="mr-1" small>mdi-phone-outline</v-icon>
            {{ accountant.phoneNumber | PhoneNumberFormatter }}
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-1">
          <v-col>
            <v-icon class="mr-1" small>mdi-domain</v-icon>
            {{ accountant.vatNumber | VatNumberFormatter }}
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions v-if="link.status == 'PENDING'">
        <v-btn
          tile
          elevation="0"
          color="accent"
          @click="acceptAccountantDialog = true"
          >{{ $t("label.accept") }}</v-btn
        >
        <v-btn
          outlined
          tile
          text
          color="grey"
          @click="rejectAccountantDialog = true"
          >{{ $t("label.refuse") }}</v-btn
        >
      </v-card-actions>
      <v-card-actions v-else-if="link.status == 'COMPLETED'">
        <v-btn
          tile
          elevation="0"
          color="accent"
          @click="sendEmail(accountant.email)"
          >{{ $t("label.contact") }}</v-btn
        >
        <v-btn
          outlined
          tile
          text
          color="grey"
          @click="deleteAccountantDialog = true"
          >{{ $t("label.remove") }}</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-card class="accountant-card pa-10" v-else> 
        <v-row :align="'center'" :justify="'center'" class="mt-3">
            <v-col :align="'center'" :justify="'center'">
                <h2>{{ $t('account.noAccountant') }}</h2>
            </v-col>
        </v-row>
        <v-row :align="'center'" :justify="'center'" class="mb-3">
            <v-col :align="'center'" :justify="'center'">
                <p>{{ $t('account.noAccountantMessage') }}</p>
            </v-col>
        </v-row>
    </v-card>
    <confirm-dialog
      :title="$t('account.deleteAccountantTitle')"
      :message="$t('account.deleteAccountantMessage')"
      :showDialog="deleteAccountantDialog"
      :loading="loading"
      @confirmed="onConfirmedDelete"
      :isError="true"
      :confirmButtonText="$t('account.deleteAccountantConfirmButton')"
    >
    </confirm-dialog>
    <confirm-dialog
      :title="$t('account.rejectAccountantTitle')"
      :message="$t('account.rejectAccountantMessage', {accountantName: accountant.fullName})"
      :showDialog="rejectAccountantDialog"
      :loading="loading"
      @confirmed="onConfirmedReject"
      :isError="true"
      :confirmButtonText="$t('account.rejectAccountantConfirmButton')"
    >
    </confirm-dialog>
    <accept-accountant-request-dialog :dialog.sync="acceptAccountantDialog" @requestAccepted="onConfirmedAccept" :requestId="link.id"></accept-accountant-request-dialog>
  </v-container>
</template>

<script>
import ConfirmDialog from "../dialog/ConfirmDialog.vue";
import { mapActions } from "vuex";
import AcceptAccountantRequestDialog from '../dialog/AcceptAccountantRequestDialog.vue';
export default {
  props: {
    accountant: {
      default: null,
      type: Object,
    },
    link: {
      default: null,
      type: Object,
    },
  },
  components: {
    ConfirmDialog,
    AcceptAccountantRequestDialog,
  },
  data() {
    return {
      loading: false,
      deleteAccountantDialog: false,
      rejectAccountantDialog: false,
      acceptAccountantDialog: false,
    };
  },
  methods: {
    ...mapActions("accountant", ["removeAccountant", "getUserLink", "rejectAccountant", "acceptAccountant"]),
    ...mapActions("alert", ["createAlert"]),
    sendEmail(email) {
      var mail = `mailto:${email}`;
      var a = document.createElement("a");
      a.href = mail;
      a.click();
    },
    onConfirmedDelete(confirmed) {
      if (confirmed) {
        this.removeAccountant(this.link.id)
          .then(() => {
            this.createAlert({
              message: this.$t("message.accountantDeleted"),
              type: "success",
            });
            this.getUserLink();
          })
          .catch(() => {
            this.createAlert({
              message: this.$t("message.genericError"),
              type: "error",
            });
          })
          .finally(() => {
            this.deleteAccountantDialog = false;
          });
      } else {
        this.deleteAccountantDialog = false;
      }
    },
    onConfirmedAccept(confirmed) {
      if (confirmed) {
        this.getUserLink();
        this.acceptAccountantDialog = false;
      } else {
        this.acceptAccountantDialog = false;
      }
    },
  onConfirmedReject(confirmed) {
      if (confirmed) {
        this.rejectAccountant(this.link.id)
          .then(() => {
            this.createAlert({
              message: this.$t("message.accountantRejected"),
              type: "success",
            });
            this.getUserLink();
          })
          .catch(() => {
            this.createAlert({
              message: this.$t("message.genericError"),
              type: "error",
            });
          })
          .finally(() => {
            this.rejectAccountantDialog = false;
          });
      } else {
        this.rejectAccountantDialog = false;
      }
    },
  },
};
</script>

<style scoped>
/* Card Styling */
.accountant-card {
  width: 600px;
  padding: 20px;
  border-radius: 0;
  border-width: 1px;
  border-style: solid;
  border-color: #e0e0e0;
}
.small-text {
  font-size: 12px;
  color: var(--v-accent-base);
}
</style>